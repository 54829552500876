<template>
  <div>
    <div class="container">
      <h3>Welcome to the buddy website!</h3>
      <h4>Recent updates</h4>
      <ul>
        <li>5/19/21 - Updated homepage</li>
        <li>5/24 - Site moved to Google Cloud</li>
        <li>5/20 - Gift site deployed</li>
        <li>5/19 - Furniture site deployed</li>
      </ul>
    </div>
    <div class="container messenger">
      <div class="messages"></div>
      <div class="add-message">
        <input type="text" class="input-message" />
        <button class="send-message">Send</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  props: {
    msg: String
  }
};
</script>
